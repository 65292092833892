
import {
  computed, defineComponent, PropType,
} from 'vue';
import Sidebar from '@/components/ui/Sidebar/Sidebar.vue';
import TrackViewBannerCompleted from '@/views/App/Course/CourseView/TrackViewBannerCompleted/TrackViewBannerCompleted.vue';
import TrackProgress from '@/components/tracks/TrackProgress';
import AtomStep from '@/components/tracks/TrackStep';
import { AtomType } from '@/domains/atom';
import { UUID } from '@/domains/common';

interface IAtomStep {
  atomType: AtomType;
  name: string;
  isCompleted: boolean;
  isFailed: boolean;
  isWasted: boolean;
  id: UUID;
}

export default defineComponent({
  name: 'AtomPlayerSidebar',

  components: {
    Sidebar,
    TrackViewBannerCompleted,
    TrackProgress,
    AtomStep,
  },

  props: {
    step: {
      type: Object as PropType<IAtomStep>,
      required: true,
    },
    isLoading: {
      type: Boolean,
      default: true,
    },
  },

  setup(props) {
    const progress = computed(() => (props.step.isCompleted ? 100 : 0));

    return {
      progress,
    };
  },
});
