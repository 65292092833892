
import Vue, { PropType } from 'vue';

import TTPrompt from '@uikit/components/TTPrompt/TTPrompt.vue';
import { createNamespacedHelpers } from 'vuex';
import TrackStepIcon from '@/components/tracks/TrackStep/TrackStepIcon';

import { AtomType } from '@/domains/atom';

const userHelpers = createNamespacedHelpers('user');
const companyStore = createNamespacedHelpers('company');

export default Vue.extend({
  name: 'AtomStep',

  components: {
    TrackStepIcon,
    TTPrompt,
  },

  inject: ['Names'],

  props: {
    atomType: {
      type: String as PropType<AtomType>,
      required: true,
    },
    name: {
      type: String,
      default: '',
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
    isCompleted: {
      type: Boolean,
      default: false,
    },
    isActive: {
      type: Boolean,
      default: false,
    },
    isPreview: {
      type: Boolean,
      default: false,
    },
    isWasted: {
      type: Boolean,
      default: false,
    },
    isFailed: {
      type: Boolean,
      default: false,
    },
    stepId: {
      type: String,
      required: true,
    },
    tooltipText: {
      type: String,
      required: true,
    },
  },

  computed: {
    ...userHelpers.mapGetters({ currentUserId: 'userId' }),
    ...companyStore.mapGetters(['companyId']),

    userId(): string {
      return this.$route.params.userId ? this.$route.params.userId : this.currentUserId;
    },

    isMyOwnResults(): boolean {
      return this.userId === this.currentUserId;
    },

    isMultilevelTest(): boolean {
      return this.atomType === AtomType.MULTILEVELTEST;
    },

    isDisabledStep(): boolean {
      switch (true) {
        case this.isDisabled:
          return true;
        case (!this.isMyOwnResults && !this.isMultilevelTest):
          return true;

        default:
          return false;
      }
    },

    atomName(): string {
      return this.name || this.$t('TrackStep.unnamed');
    },

    trackSessionId(): string {
      return this.$route.params.trackSessionId;
    },

    dataTest(): string {
      return this.atomType === AtomType.MULTICONTENT ? 'lesson' : 'multileveltest';
    },

    analyzedPages(): string[] {
      return [
        this.Names.R_APP_LEARNING_ASSIGNMENT_TRACK_STEP_SCORM_VIEW,
        this.Names.R_APP_LEARNING_ASSIGNMENT_TRACK_STEP_MULTICONTENT,
      ];
    },
  },

  methods: {
    sendAnalytic() {
      const data = {
        companyId: this.companyId,
        playerSessionId: this.$route.params.playerSessionId,
        stepId: this.$route.params.stepId,
        trackSessionId: this.trackSessionId,
      };

      if (this.analyzedPages.includes(this.$route.name!)) {
        this.$di.tmt.sendEvent('send', 'click', this.name, 'right-sidebar-link', 'internalLink', data);
      }
    },
  },

});
